import { Injectable } from '@angular/core'
import { MultiStatusOverlayContent } from '@services/proficloud.interfaces'
import { Subject } from 'rxjs'
import { PCStatusOverlayConfig } from 'src/app/modules/shared/components/pc-status-overlay/pc-status-overlay.component'

@Injectable({
  providedIn: 'root',
})
export class StatusOverlayService {
  addDeviceErrorMessage: string = ''

  // multi status overlay
  multiStatusOverlay$ = new Subject<MultiStatusOverlayContent | false>()

  // EMMA Simulator

  statusOverlays: Record<string, PCStatusOverlayConfig> = {
    showDeviceCreatedBusy: {
      title: 'Adding device to Proficloud.io...',
      closeable: false,
      type: 'spinner',
      message: 'The device will be added, please be patient.',
    },

    showDeviceCreatedSuccess: {
      title: 'Successfully added device to Proficloud.io',
      closeable: false,
      type: 'success',
      message: 'Successfully added device to Proficloud.io.',
    },

    showDeviceCreatedError: {
      title: 'The device could not be added',
      closeable: false,
      type: 'error',
      message: 'An error ocurred while adding the device, please try again.',
    },

    showDeviceCreatedNotRegisteredError: {
      title: 'The device could not be added',
      closeable: false,
      type: 'error',
      message:
        'Unfortunately, the UUID you have entered seems to be invalid. Please contact inbox@phoenixcontact-sb.io if you have any questions or need support.',
    },

    showDeviceCreatedAddedElsewhereError: {
      title: 'The device could not be added',
      closeable: false,
      type: 'error',
      message: 'This device has already been added to an account.',
    },

    showDeviceCreatedCustomError: {
      title: 'The device could not be added',
      closeable: false,
      type: 'error',
      message: this.addDeviceErrorMessage,
    },

    showDeviceDeletedBusy: {
      title: 'Device is being deleted...',
      closeable: false,
      type: 'spinner',
      message: 'The device is being deleted, data is being removed, device is being removed from subscriptions...',
    },

    showDeviceDeletedSuccess: {
      title: 'Successfully deleted device from Proficloud.io',
      closeable: false,
      type: 'success',
      message: 'The device has been successfully removed from Proficloud.io.',
    },

    showDeviceDeletedError: {
      title: 'The device could not be deleted',
      closeable: false,
      type: 'error',
      message: 'An error ocurred while deleting the device, please try again.',
    },

    showDeviceChangedBusy: {
      title: 'Device information is being updated...',
      closeable: false,
      type: 'spinner',
      message: 'Your changes are being updated...',
    },

    showDeviceChangedSuccess: {
      title: 'Successfully updated device information',
      closeable: false,
      type: 'success',
      message: 'The changes you have made have been saved.',
    },

    showDeviceChangedError: {
      title: 'The device information could not be updated',
      closeable: true,
      type: 'error',
      message: 'An error ocurred while updating the device information, please try again.',
    },

    showFirmwareUpdateActivatedSuccess: {
      title: 'Successfully activated automatic firmware update',
      closeable: false,
      type: 'success',
      message: 'The automatic firmware update for this device has been successfully activated.',
    },

    showFirmwareUpdateSuccess: {
      title: 'Successfully started firmware update',
      closeable: false,
      type: 'success',
      message: 'The firmware update has been started. You can see its progress in the device details.',
    },

    showFirmwareUpdateError: {
      title: 'Firmware update could not be started',
      closeable: false,
      type: 'error',
      message: 'The firmware update could not be started, please try again.',
    },

    showDeviceTokenResetSuccess: {
      title: 'Device token has been reset successfully',
      closeable: false,
      type: 'success',
      message: 'The device token has been reset successfully.',
    },

    showDeviceTokenResetError: {
      title: 'Device token could not be reset',
      closeable: false,
      type: 'error',
      message: 'The device token could not be reset, please try again.',
    },

    showDeviceAlertCreatedBusy: {
      title: 'Alert is being created...',
      closeable: false,
      type: 'spinner',
      message: 'The alert is being created...',
    },

    showDeviceAlertCreatedSuccess: {
      title: 'Alert successfully created',
      closeable: false,
      type: 'success',
      message: 'You have successfully set up an alert. The specified persons will now be notified as soon as the device changes to the specified status.',
    },

    showDeviceAlertCreatedError: {
      title: 'Alert could not be created',
      closeable: false,
      type: 'error',
      message: 'An error occured while creating the alert, please try again.',
    },

    showDeviceAlertDeletedBusy: {
      title: 'Alert is being deleted...',
      closeable: false,
      type: 'spinner',
      message: 'The alert is being deleted...',
    },

    showDeviceAlertDeletedSuccess: {
      title: 'Alert successfully deleted',
      closeable: false,
      type: 'success',
      message: 'You have successfully deleted this alert. The entered persons will not receive any further emails through this alert.',
    },

    showDeviceAlertDeletedError: {
      title: 'Alert could not be deleted',
      closeable: false,
      type: 'error',
      message: 'An error occured while deleting the alert, please try again.',
    },

    showDeviceAlertChangedBusy: {
      title: 'Saving alert changes...',
      closeable: false,
      type: 'spinner',
      message: 'Saving alert changes...',
    },

    showDeviceAlertChangedSuccess: {
      title: 'Alert successfully changed',
      closeable: false,
      type: 'success',
      message: 'You have successfully changed this alert.',
    },

    showDeviceAlertChangedError: {
      title: 'Alert could not be changed',
      closeable: false,
      type: 'error',
      message: 'An error occured while changing the alert, please try again.',
    },

    showDeviceAlertPausedBusy: {
      title: 'Alert is being paused...',
      closeable: false,
      type: 'spinner',
      message: 'The alert is being paused...',
    },

    showDeviceAlertPausedSuccess: {
      title: 'Alert successfully paused',
      closeable: false,
      type: 'success',
      message:
        'You have successfully paused this alert. You can reactivate your alert at any time, just go to the context menu of the alert and click on &#34;Activate&#34;.',
    },

    showDeviceAlertPausedError: {
      title: 'Alert could not be paused',
      closeable: false,
      type: 'error',
      message: 'An error occured while pausing the alert, please try again.',
    },

    showDeviceAlertActivatedBusy: {
      title: 'Alert is being activated...',
      closeable: false,
      type: 'spinner',
      message: 'The alert is being activated...',
    },

    showDeviceAlertActivatedSuccess: {
      title: 'Alert successfully activated',
      closeable: false,
      type: 'success',
      message:
        'You have successfully activated this alert. You can pause your alert at any time, just go to the context menu of the alert and click on &#34;Pause&#34;.',
    },

    showDeviceAlertActivatedError: {
      title: 'Alert could not be activated',
      closeable: false,
      type: 'error',
      message: 'An error occured while activating the alert, please try again.',
    },

    showNotificationChannelCreatedBusy: {
      title: 'Notification channel is being created...',
      closeable: false,
      type: 'spinner',
      message: 'The notification channel is being created...',
    },

    showNotificationChannelCreatedSuccess: {
      title: 'Notification channel successfully created',
      closeable: false,
      type: 'success',
      message: 'You have successfully created the notification channel.',
    },

    showNotificationChannelCreatedError: {
      title: 'Notification channel could not be created',
      closeable: false,
      type: 'error',
      message: 'An error occured while creating the notification channel, please try again.',
    },

    showNotificationChannelDeletedBusy: {
      title: 'Notification channel is being deleted...',
      closeable: false,
      type: 'spinner',
      message: 'The notification channel is being deleted...',
    },

    showNotificationChannelDeletedSuccess: {
      title: 'Notification channel successfully deleted',
      closeable: false,
      type: 'success',
      message: 'You have successfully deleted this notification channel.',
    },

    showNotificationChannelDeletedError: {
      title: 'Notification channel could not be deleted',
      closeable: false,
      type: 'error',
      message: 'An error occured while deleting the notification channel, please try again.',
    },

    showNotificationChannelChangedBusy: {
      title: 'Notification channel is being changed...',
      closeable: false,
      type: 'spinner',
      message: 'Notification channel is being changed...',
    },

    showNotificationChannelChangedSuccess: {
      title: 'Notification channel successfully changed',
      closeable: false,
      type: 'success',
      message: 'You have successfully changed this notification channel.',
    },

    showNotificationChannelChangedError: {
      title: 'Notification channel could not be changed',
      closeable: false,
      type: 'error',
      message: 'An error occured while changing the notification channel, please try again.',
    },

    showDeviceGroupCreatedBusy: {
      title: 'Device group is being created...',
      closeable: false,
      type: 'spinner',
      message: 'The device group is being created and the devices are added to it.',
    },

    showDeviceGroupCreatedSuccess: {
      title: 'Successfully created device group',
      closeable: false,
      type: 'success',
      message: 'The device group has been successfully created.',
    },

    showDeviceGroupCreatedError: {
      title: 'Device group could not be created',
      closeable: false,
      type: 'error',
      message: 'The device group could not be created, please try again.',
    },

    showDeviceGroupDeletedBusy: {
      title: 'Device group is being deleted...',
      closeable: false,
      type: 'spinner',
      message: 'The device group is being deleted.',
    },

    showDeviceGroupDeletedSuccess: {
      title: 'Successfully deleted device group',
      closeable: false,
      type: 'success',
      message: 'The device group has been successfully deleted.',
    },

    showDeviceGroupDeletedError: {
      title: 'Device group could not be deleted',
      closeable: false,
      type: 'error',
      message: 'The device group could not be deleted, please try again.',
    },

    showDeviceGroupChangedBusy: {
      title: 'Device group is being updated...',
      closeable: false,
      type: 'spinner',
      message: 'The device group is being updated.',
    },

    showDeviceGroupChangedSuccess: {
      title: 'Successfully updated device group',
      closeable: false,
      type: 'success',
      message: 'The device group has been successfully updated.',
    },

    showDeviceGroupChangedError: {
      title: 'Device group could not be updated',
      closeable: false,
      type: 'error',
      message: 'The device group could not be updated, please try again.',
    },

    showEMMASimulationCreatedBusy: {
      title: 'Creating EMMA Simulation',
      closeable: false,
      type: 'spinner',
      message: 'The simulation will be created, please be patient, it takes a while.',
    },

    showEMMASimulationDeletedBusy: {
      title: 'Deleting EMMA Simulation',
      closeable: false,
      type: 'spinner',
      message: 'The simulation will be deleted, please be patient, it takes a while.',
    },

    showEMMASimulationEditBusy: {
      title: 'Editing EMMA Simulation',
      closeable: false,
      type: 'spinner',
      message: 'The simulation will be edited, please be patient.',
    },

    showDevicesDeletedBusy: {
      title: 'Multiple devices are being deleted...',
      closeable: false,
      type: 'spinner',
      message: 'The devices are being deleted, data is being removed, devices are being removed from subscriptions.',
    },

    showDevicesDeletedSuccess: {
      title: 'Successfully deleted multiple devices from Proficloud.io',
      closeable: false,
      type: 'success',
      message: 'The devices have been successfully removed from Proficloud.io.',
    },

    showDevicesDeletedError: {
      title: 'The devices could not be deleted',
      closeable: false,
      type: 'error',
      message: 'An error ocurred while deleting the devices, please try again.',
    },
  }
}
