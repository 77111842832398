<!-- device colored header-->
<div
  [ngClass]="device.metadata?.trafficLightNumber | deviceCssPrefix: device.metadata?.con_connected"
  class="device"
  [style.border-bottom-left-radius]="device.expansionState === 'expanded' ? '0px' : '4px'"
  [style.border-bottom-right-radius]="device.expansionState === 'expanded' ? '0px' : '4px'"
  [style.border-bottom-width.px]="device.expansionState === 'expanded' ? 1 : 0"
  data-analytics="device-item">
  <flex-col>
    <flex-row>
      <!-- health status -->
      <flex-col [ngClass]="[cssPrefix + 'device-section-status-state', 'stateSection']" data-analytics="device-item-health-status">
        <div class="topRow">
          <!-- State Icons for the device. Only shown if there is an error or a warning-->
          <app-icon
            [name]="device?.metadata?.trafficLightNumber | stateIconName"
            [color]="device?.metadata?.trafficLightNumber | stateIconColor: device.metadata?.con_connected"
            class="icon"
            data-analytics="device-item-state-icon"></app-icon>
        </div>
      </flex-col>

      <!-- online status -->
      <flex-col [ngClass]="[cssPrefix + 'device-section-online-state', 'stateSection']" data-analytics="device-item-online-status">
        <flex-row class="topRow" style="justify-content: space-around">
          <app-device-online-icon [liveStatus]="device.metadata.con_connected" class="icon" data-analytics="device-item-online-icon"></app-device-online-icon>
        </flex-row>
        @if (device.metadata.con_connected) {
          <div class="smallText" style="text-align: center" data-analytics="device-item-online">Online</div>
        }
      </flex-col>
    </flex-row>
    @if (!device.metadata.con_connected) {
      <div class="smallText lastOnline" style="text-align: right" data-analytics="device-item-last-seen">
        {{ 'DEVICE.LAST_SEEN' | translate }}<br class="lastOnline" />
        {{ device.metadata.con_connected_ts | lastOnlineText }}
      </div>
    }
  </flex-col>

  <!-- center (name and tag)-->
  <flex-col style="overflow: hidden; text-overflow: ellipsis" class="section" data-analytics="device-item-device-name-and-tags-section">
    <flex-row-between-center class="topRow iconHeight">
      <div
        [title]="device.metadata.deviceName"
        [ngClass]="[cssPrefix + 'device-section-device-name', 'bigText', 'deviceName', 'semibold']"
        data-analytics="device-item-device-name">
        {{ device.metadata.deviceName }}
      </div>
      <div [ngClass]="[cssPrefix + 'device-section-tags']" class="tagsContainer" data-analytics="device-item-tags-container">
        @for (tag of device.metadata.tags; track tag) {
          <div
            class="tag deviceTag"
            [style.background]="'#2F2F2F'"
            data-analytics="analytics-device-management-service-device-overview-list-device-header-tags-and-element-type-tags-tag"
            [title]="tag.length > 15 ? tag : ''"
            data-analytics="device-item-tags">
            {{ tag | truncate: [15] }}
          </div>
        }
      </div>
    </flex-row-between-center>
    <flex-row-between-center style="width: 100%; overflow: hidden" data-analytics="device-item-uuid-and-device-type-section">
      <div>
        <div [ngClass]="['smallText', cssPrefix + 'device-section-uuid']" style="white-space: nowrap" data-analytics="device-item-device-uuid">
          {{ device.metadata.uuid || '59C5BFA6-1568-4DE2-A8C0-530C0B7534D3' }}
        </div>
      </div>
      <div [ngClass]="[cssPrefix + 'device-section-type', 'deviceType', 'smallText']" data-analytics="device-item-device-type">
        {{ device.metadata.con_DeviceType || 'Unknown' }}
      </div>
    </flex-row-between-center>
  </flex-col>

  <flex-row class="section" data-analytics="device-item-location-section">
    <flex-row>
      @if (showMap) {
        <div class="icon">
          @if (deviceService.hasValidLatLng(device)) {
            <app-icon
              [ngClass]="[cssPrefix + 'device-section-location']"
              style="cursor: pointer"
              (click)="deviceService.onDeviceMapClick($event, device)"
              name="mapMarker"
              [color]="device?.metadata?.con_connected | mapIconColor"
              data-analytics="device-item-map-icon"></app-icon>
          }
        </div>
      }
    </flex-row>
    @if (showCheckBox) {
      <flex-row>
        <div style="margin-top: 29px; margin-left: 9px">
          <mat-checkbox
            [id]="'device-item-checkbox'"
            class="dms-device-checkbox"
            (click)="endpointToggle($event)"
            (change)="checkedChanged($event.checked)"
            [checked]="device.checked"
            [attr.data-analytics]="'device-item-checkbox'"></mat-checkbox>
        </div>
      </flex-row>
    }
  </flex-row>

  @if (!ui.uiData.isMobile) {
    <div-relative class="icon" style="margin-top: 8px; margin-right: 8px; height: 24px; width: 24px">
      <app-icon
        style="height: 24px; width: 24px; margin: 0px"
        [ngClass]="[cssPrefix + 'device-section-more']"
        name="more"
        [id]="device?.metadata?.uuid + '-more'"
        [color]="device?.metadata?.con_connected | mapIconColor"
        (click)="onMenuIconClick($event, device)"
        data-analytics="device-item-context-menu">
      </app-icon>
      @if (device === deviceService.visibleMenuDevice) {
        <app-proficloud-drop-down
          [items]="dropDownOptions"
          (onItemClicked)="menuClicked($event)"
          (clickOutside)="deviceService.visibleMenuDevice = undefined"
          data-analytics="device-item-drop-down-menu">
        </app-proficloud-drop-down>
      }
    </div-relative>
  }
</div>
