import { Pipe, PipeTransform } from '@angular/core'
import { PCStatusOverlayConfig } from '../components/pc-status-overlay/pc-status-overlay.component'

@Pipe({
  name: 'mapOverlayConfig',
})
export class MapOverlayConfigPipe implements PipeTransform {
  transform(config: PCStatusOverlayConfig) {
    if (config) {
      return {
        title: config.title || '',
        closeable: config.closeable || config.type === 'error',
      }
    }
  }
}
