import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-number',
  templateUrl: './pc-input-number.component.html',
  styleUrls: ['./pc-input-number.component.scss'],
})
export class PcInputNumberComponent extends PcInputBaseComponent {
  // Required
  @Input() control: UntypedFormControl

  // Required unless it will look crap
  @Input({ required: true }) placeholder: string

  @Output() valueChanged: EventEmitter<number> = new EventEmitter()

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() density: 'small' | 'x-small' | 'regular' = 'regular'

  @Input() variant: 'fit-width'

  // TODO: Implement this in the template
  @Input() errorHint: string

  // TODO: Implement this in the template
  @Input() hint: string

  @Input() inputID: string

  @Input() standAlone: boolean = false

  @Input() suffix: string

  @Input() minValue?: number

  inputFieldChanged(event: Event) {
    const value = (event.target as unknown as HTMLInputElement).valueAsNumber
    this.valueChanged.emit(value)
  }

  resetInput() {
    this.control.setValue('')
  }
}
