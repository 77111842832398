import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core'

@Directive({
  selector: '[dragResize]',
})
export class DragResizeDirective {
  @Output() onDrop = new EventEmitter<number>()

  // Emit the absolute position of the mouse
  @Output() onDrag = new EventEmitter<number>()

  private initialX = 0

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mousedown', ['$event'])
  onDragStart(event: any): void {
    event.preventDefault()

    let element = this.el.nativeElement.classList.contains('dragger') ? this.el.nativeElement : this.el.nativeElement.parentElement
    const rect = element.getBoundingClientRect()
    this.initialX = rect.left

    const moveListener = this.renderer.listen('document', 'mousemove', (moveEvent: MouseEvent) => {
      this.onDrag.emit(moveEvent.clientX)
    })

    const upListener = this.renderer.listen('document', 'mouseup', (moveEvent: MouseEvent) => {
      moveListener()
      upListener()
      this.onDrop.emit(moveEvent.clientX - this.initialX - 4)
    })
  }
}
