<!-- Renders the PRIMARY variant of the button only -->
@if (variant === 'primary') {
  <button
    #innerButton
    mat-raised-button
    color="primary"
    [style.min-Width]="buttonWidth"
    [attr.data-analytics]="analyticsID || 'button'"
    [id]="inputID"
    [ngClass]="'pc-button pc-button--primary'"
    [class.no-uppercase]="noUppercase"
    [class.pc-button--large]="size === 'large'"
    [class.pc-button--standard]="size === 'regular'"
    [class.pc-button--small]="size === 'small'"
    [class.pc-button--narrow]="size === 'narrow'"
    [class.pc-button--minimal]="size === 'minimal'"
    [class.pc-button--wide]="wide"
    [class.pc-button--inactive]="!active"
    [class.pc-button__min-width]="minimalSize"
    [disabled]="disabled"
    (click)="buttonClicked($event)"
    (mouseenter)="hoverChanged($event)"
    (mouseleave)="hoverChanged($event)"
    [@hoverState]="hover ? { value: 'hovered', params: {} } : { value: 'default', params: { bgColor: 'var(--primary)' } }"
    [matTooltip]="tooltip">
    @if (isLoading) {
      <mat-progress-spinner mode="indeterminate" customMatSpinner [cuzColor]="'var(--white-smoke)'" [diameter]="24"></mat-progress-spinner>
    } @else {
      <ng-container *ngTemplateOutlet="beforeContentTpl"></ng-container>

      <ng-container *ngTemplateOutlet="textContentTpl"></ng-container>

      @if (text?.length && !verificationVisible && !iconOnly) {
        <span>
          {{ text }}
        </span>
      }

      @if (iconOnly) {
        <span>
          <app-icon
            [class.pc-button__icon--small]="size === 'small'"
            [class.pc-button__icon]="size === 'regular' || size === 'large'"
            [name]="icon"
            [color]="disabled ? 'var(--regent-gray)' : 'var(--white)'">
          </app-icon>
        </span>
      }
      @if (verificationVisible && !iconOnly) {
        <app-icon [@verifyAnimation] class="pc-button__verification-icon" [color]="'var(--white)'" [name]="'check'"></app-icon>
      }
      <ng-container *ngTemplateOutlet="afterContentTpl"></ng-container>
    }
  </button>
}

<!-- Renders the SECONDARY variant of the button only -->
@if (variant === 'secondary') {
  <button
    mat-raised-button
    [style.min-Width]="buttonWidth"
    [id]="inputID"
    [ngClass]="'pc-button pc-button--secondary'"
    [class.no-uppercase]="noUppercase"
    [class.pc-button--large]="size === 'large'"
    [class.pc-button--standard]="size === 'regular'"
    [class.pc-button--small]="size === 'small'"
    [class.pc-button--minimal]="size === 'minimal'"
    [class.pc-button--wide]="wide"
    [class.pc-button--inactive]="!active"
    [class.pc-button--active]="active"
    [class.pc-button__min-width]="minimalSize"
    [disabled]="disabled"
    (click)="buttonClicked($event)"
    (mouseenter)="hoverChanged($event)"
    (mouseleave)="hoverChanged($event)"
    [@hoverState]="
      hover ? { value: 'hovered', params: { bgColorHovered: 'var(--white-smoke)' } } : { value: 'default', params: { bgColor: 'var(--alabaster)' } }
    ">
    @if (isLoading) {
      <mat-progress-spinner mode="indeterminate" customMatSpinner [cuzColor]="'var(--primary)'" [diameter]="24"></mat-progress-spinner>
    } @else {
      <div style="display: flex; flex-direction: row !important">
        <ng-container *ngTemplateOutlet="beforeContentTpl"></ng-container>
        <ng-container *ngTemplateOutlet="textContentTpl"></ng-container>
        @if (text?.length && !verificationVisible && !iconOnly) {
          <span>
            {{ text }}
          </span>
        }
        @if (verificationVisible && !iconOnly) {
          <app-icon [@verifyAnimation] class="pc-button__verification-icon" color="var(--primary)" [name]="'check'"></app-icon>
        }
        @if (iconOnly) {
          <span>
            <app-icon
              [class.pc-button__icon--small]="size === 'small'"
              [class.pc-button__icon]="size === 'regular' || size === 'large'"
              [name]="icon"
              [color]="disabled ? 'var(--regent-gray)' : 'var(--black)'">
            </app-icon>
          </span>
        }
        <ng-container *ngTemplateOutlet="afterContentTpl"></ng-container>
      </div>
    }
  </button>
}

<!-- Renders the DELETION variant of the button only -->
@if (variant === 'deletion') {
  <button
    #innerDeletionButton
    mat-button
    data-analytics="button"
    color="error"
    [id]="inputID"
    [ngClass]="'pc-button pc-button--deletion'"
    [class.pc-button--large]="size === 'large'"
    [class.pc-button--standard]="size === 'regular'"
    [class.pc-button--small]="size === 'small'"
    [class.pc-button--wide]="wide"
    [class.pc-button--inactive]="!active"
    [class.pc-button__min-width]="minimalSize"
    [disabled]="disabled"
    (click)="buttonClicked($event)"
    (mouseenter)="hoverChanged($event)"
    (mouseleave)="hoverChanged($event)"
    [@hoverState]="
      hover ? { value: 'hovered', params: { bgColorHovered: 'var(--white-smoke)' } } : { value: 'default', params: { bgColor: 'var(--alabaster)' } }
    ">
    @if (isLoading) {
      <mat-progress-spinner mode="indeterminate" customMatSpinner [cuzColor]="'red'" [diameter]="24"></mat-progress-spinner>
    } @else {
      <div style="display: flex; flex-direction: row !important">
        <ng-container *ngTemplateOutlet="beforeContentTpl"></ng-container>
        <ng-container *ngTemplateOutlet="textContentTpl"></ng-container>
        @if (text?.length && !verificationVisible && !iconOnly) {
          <span>
            {{ text }}
          </span>
        }
        @if (verificationVisible && !iconOnly) {
          <app-icon [@verifyAnimation] class="pc-button__verification-icon" [color]="'var(--error-red)'" [name]="'check'"></app-icon>
        }
        <ng-container *ngTemplateOutlet="afterContentTpl"></ng-container>
      </div>
    }
  </button>
}

<ng-template #afterContentTpl>
  <ng-content></ng-content>
</ng-template>

<ng-template #textContentTpl>
  <ng-content select="[text]"></ng-content>
</ng-template>

<ng-template #beforeContentTpl>
  <ng-content select="[before]"></ng-content>
</ng-template>
