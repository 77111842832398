<div>
  @if (form | inputIsActive: config) {
    <mat-form-field
      [subscriptSizing]="standAlone ? 'dynamic' : 'fixed'"
      class="input-number"
      [class.input-number--fit-width]="variant === 'fit-width'"
      [class.density-small]="density === 'small'"
      [class.density-x-small]="density === 'x-small'"
      [class.input-number--small]="size === 'small'">
      <mat-label>{{ placeholder }}</mat-label>
      <span style="display: flex">
        <input
          [min]="minValue ? minValue : ''"
          type="number"
          matInput
          [placeholder]="placeholder"
          [id]="inputID"
          (input)="inputFieldChanged($event)"
          [formControl]="control" />
        @if (suffix) {
          <span>{{ suffix }}</span>
        }
      </span>
      @if (control?.errors && control?.touched) {
        <app-icon class="input-number__icon" [class.input-number__icon--small]="size === 'small'" [color]="'var(--error-red)'" name="warning" matSuffix>
        </app-icon>
      }
      @if (!control?.errors && control?.touched) {
        <app-icon
          class="input-number__icon input-number__icon--reset"
          [class.input-number__icon--small]="size === 'small'"
          color="var(--primary)"
          name="cross"
          matSuffix
          (click)="resetInput()"></app-icon>
      }
    </mat-form-field>
  }
</div>
