import { AfterViewInit, Component, Input } from '@angular/core'
import { environment } from '@environments/environment'
import { AuthorisationService } from '@services/authorisation.service'
import { HttpBaseService } from '@services/http-base.service'
import { DeviceInfo, DropDownItem } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'
import { sharedAnimations } from 'src/app/modules/shared/shared.animations'
import { DeviceEventHandlerService } from '../../services/device-event-handler.service'
import { DeviceService } from '../../services/device.service'

@Component({
  selector: 'app-device-item',
  templateUrl: './device-item.component.html',
  styleUrls: ['./device-item.component.scss'],
  animations: [sharedAnimations.heightSlideEnterLeave, sharedAnimations.heightSlide],
})
export class DeviceItemComponent implements AfterViewInit {
  showMenu = false

  chinaMode = environment.chinaMode

  @Input()
  dropDownOptions: DropDownItem[] = [
    {
      icon: 'edit',
      label: 'DEVICE.EDIT_DEVICE',
      id: 'edit',
      disabled: this.authorization.isViewer(this.proficloud.currentOrganisation),
    },
    {
      icon: 'reset',
      label: 'DEVICE.RESET_DEVICE_TOKEN',
      id: 'reset',
      disabled: this.authorization.isViewer(this.proficloud.currentOrganisation),
    },
    {
      icon: 'face_agent',
      label: 'DEVICE.SUPPORT_FOR_THIS_DEVICE',
      id: 'support',
    },
    {
      icon: 'trash',
      label: 'DEVICE.DELETE_DEVICE',
      id: 'delete',
      disabled: !this.authorization.isAdmin(this.proficloud.currentOrganisation),
    },
  ]

  @Input()
  device: DeviceInfo

  @Input()
  showMap = true

  @Input()
  showEdit = true

  @Input() showCheckBox = true

  @Input()
  cssPrefix = 'dms-'

  constructor(
    public app: AppService,
    public deviceService: DeviceService,
    public ui: UiService,
    public proficloud: ProficloudService,
    public authorization: AuthorisationService,
    public deviceEventHandler: DeviceEventHandlerService,
    private httpBase: HttpBaseService
  ) {}

  ngAfterViewInit() {
    const addDeviceParam = localStorage.getItem('addDevice')
    if (addDeviceParam) {
      setTimeout(() => {
        this.deviceService.showAddDeviceOverlay$.next({
          show: true,
          virtual: addDeviceParam === 'virtual',
        })
      }, 100)
    }
  }

  menuClicked(item: DropDownItem) {
    if (item.action) {
      item.action.method.apply(item.action.context, [item.action.input])
      delete this.deviceService.visibleMenuDevice
      return
    }

    if (item.id === 'edit') {
      this.openEditDialog()
    } else if (item.id === 'reset') {
      this.openResetTokenDialog()
    } else if (item.id === 'support') {
      this.openSupportPage()
    } else if (item.id === 'delete') {
      this.openDeleteDeviceDialog()
    }
  }

  openEditDialog() {
    if (!this.authorization.isViewer(this.proficloud.currentOrganisation)) {
      this.deviceService.deviceEdit$.next(this.device)
    }
    delete this.deviceService.visibleMenuDevice
  }

  openResetTokenDialog() {
    if (!this.authorization.isViewer(this.proficloud.currentOrganisation)) {
      this.proficloud.showResetTokenOverlay$.next(this.device)
    }

    delete this.deviceService.visibleMenuDevice
  }

  openDeleteDeviceDialog() {
    if (this.authorization.isAdmin(this.proficloud.currentOrganisation)) {
      this.deviceService.showDeleteDeviceOverlay$.next(this.device)
    }

    delete this.deviceService.visibleMenuDevice
  }

  openSupportPage() {
    window.open(
      this.httpBase.backendUrls.landingUrl +
        '/device-management-service-support/?your-name=' +
        this.proficloud.keycloakData.userDetails?.data.firstName +
        ' ' +
        this.proficloud.keycloakData.userDetails?.data.lastName +
        '&your-email=' +
        this.proficloud.keycloakData.userDetails?.data.email +
        '&your-account-id=' +
        this.proficloud.keycloakData.userDetails?.data.id +
        '&device-uuid=' +
        this.device.metadata.uuid +
        '&endpoint-id=' +
        this.device.endpointId,
      '_blank'
    )
  }

  /**
   * When user click on ... to open or close menu
   */
  onMenuIconClick(event: MouseEvent, device: DeviceInfo) {
    // was open, wants this closed, keep all other closed
    if (device === this.deviceService.visibleMenuDevice) {
      delete this.deviceService.visibleMenuDevice
    } else {
      // was NOT open. open this one instead, close others.
      this.deviceService.visibleMenuDevice = device
    }
    event.stopPropagation()
  }

  stopEventPropagation(event: MouseEvent) {
    event.stopPropagation()
    event.preventDefault()
  }

  lastOnlineText() {
    if (!this.device.metadata.con_connected_ts) {
      return 'never'
    } else {
      const date = new Date(this.device.metadata.con_connected_ts)
      const now = new Date()
      const millidifference: number = now.getTime() - date.getTime()
      if (millidifference / 1000 <= 60) {
        return String(Math.round(millidifference / 1000)) + 's ago'
      }
      if (millidifference / 60000 <= 60) {
        return String(Math.round(millidifference / 60000)) + 'm ago'
      }
      if (millidifference / 3600000 <= 24) {
        return String(Math.round(millidifference / 3600000)) + 'h ago'
      }
      return String(Math.round(millidifference / 86400000)) + 'd ago'
    }
  }

  endpointToggle(event: Event) {
    event.stopPropagation()
  }

  checkedChanged(checked: boolean) {
    this.device.checked = checked
    if (checked) {
      this.deviceEventHandler.selectedDevices.add(this.device)
      this.deviceEventHandler.changeCheckboxState()
    } else {
      if (this.deviceEventHandler.selectedDevices.size === 1) {
        this.deviceEventHandler.checkboxState = 'empty'
      } else if (this.deviceEventHandler.checkboxState === 'checked') {
        this.deviceEventHandler.checkboxState = 'indeterminate'
      }
      this.deviceEventHandler.selectedDevices = new Set([...this.deviceEventHandler.selectedDevices].filter((device) => device !== this.device))
    }
  }
}
